import React, { useEffect, useState } from 'react'

import { HomeOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

import { FaArrowRightLong } from "react-icons/fa6";

import PROJECTS from './Projects_data';

import './Project.scss'

const Project = () => {
    const projects = PROJECTS;
    const [projectData, setProjectData] = useState();
    const id = window.location.pathname.split('/')[2]

    useEffect(() => {
        const project = projects.find(project => project.id === id);
        setProjectData(project);
    }, [id, projects])

    return (
        <div className='project-page'>
            <div className='project-container'>
                <h2 className='type'>
                    {projectData?.type}<span className='dot'>.</span>
                </h2>
                <h1 className='title'>
                    {projectData?.title}
                </h1>

                <Breadcrumb
                    className='breadcrumb'
                    separator=">"
                    items={[
                        {
                            href: '/',
                            title: <>
                                <HomeOutlined />
                                <span>Home</span>
                            </>,
                        },
                        {
                            href: '/#myWork',
                            title: (
                                <>
                                    <AppstoreOutlined />
                                    <span>Projects</span>
                                </>
                            ),
                        },
                        {
                            title: projectData?.title,
                        },
                    ]}
                />

                <div className='project-content'>
                    <div className='left'>
                        <p>
                            {projectData?.description}
                        </p>
                    </div>
                    <div className='right'>
                        <h3>
                            Client
                        </h3>
                        <p>
                            {projectData?.client}
                        </p>

                        {projectData?.country && <>
                            <h4>From</h4>
                            <div className='project-origin'>
                                <img src={projectData?.countryFlag} alt={projectData?.country} />
                                <p>
                                    {projectData?.country}
                                </p>
                            </div>
                        </>}
                    </div>
                </div>

                <div className='open-project-container'>
                    {projectData?.url && <a className='open-project' href={projectData?.url} target='_blank' rel='noreferrer'>
                        View Project <FaArrowRightLong />
                    </a>}
                </div>

                <div className='tech-stack'>
                    <h3>
                        Tech Stack
                    </h3>
                    <div className='tech-stack-content'>
                        {projectData?.technologies.map((tech, index) => {
                            return (
                                <span key={index}>
                                    {tech}
                                </span>
                            )
                        })}
                    </div>
                </div>

                <div className='project-images'>
                    {projectData?.images.map((image, index) => {
                        return (
                            <img key={index} src={image} alt={projectData.title} />
                        )
                    })}
                </div>
            </div>

            <footer>
                <p>© 2023 Isuru Ariyarathna. All rights reserved.</p>
                <div className='top'>
                    <button
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }}
                        class="button">
                        <svg viewBox="0 0 384 512" class="svgIcon">
                            <path
                                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                            ></path>
                        </svg>
                    </button>
                </div>

                <div className='logo'>
                    <div className="logo">
                        <a href="/#hero"><span className='name'>Isuru</span><span className='dot'>.</span>_</a>
                    </div>
                </div>
            </footer>
        </div >
    )
}

export default Project