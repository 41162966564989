import React, { useEffect, useState } from 'react';
import './navbar.scss';

const Navigator = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                document.querySelector('.navbar').classList.add('navbar--scroll');
            } else {
                document.querySelector('.navbar').classList.remove('navbar--scroll');
            }
        });
    }, []);

    // add class to navbar based on route
    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/' || path === '/products/lumolearn') {
            document.querySelector('.navbar').classList.add('navbar--home');
        } else {
            document.querySelector('.navbar').classList.remove('navbar--home');
        }
    }, []);


    return (
        <>
            <div className='navbar'>
                <div className='logo'>
                    <div className="logo">
                        <a href="/#hero"><span className='name'>Isuru</span><span className='dot'>.</span>_</a>
                    </div>
                </div>
                <div className='nav-links'>
                    <a id='navservice' href='/#about'>About</a>
                    <a id='navportfolio' href='/#expertise'>Expertise</a>
                    <a id='navblog' href='/#experience'>Experiences</a>
                    <a id='navmyWork' href='/#myWork'>My Work</a>
                    <a id='navblogs'
                        rel='noopener noreferrer'
                        target='_blank'
                        href='https://medium.com/@isuruariyarathna2k00'>Blogs</a>
                </div>
                <div className="cta">
                    <a
                        href="/#getintouch"
                    >Contact</a>
                </div>
            </div>

            <div className='mobile-nav-toggle'>
                <div className="logo">
                    <a href="#hero"><span className='name'>Isuru</span><span className='dot'>.</span>_</a>
                </div>

                <div className='hamburger'
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <button class={
                        isMenuOpen ? " menu__icon active-menu" : "menu__icon"
                    }>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>

            <div
                className={isMenuOpen
                    ? "mobile-nav-container-active"
                    : 'mobile-nav-container'}
            >
                <div className='mobile-nav-box'>
                    <div className='mobile-nav-links'>
                        <a id='navservice' href='/#about'
                            onClick={() => setIsMenuOpen(false)}
                        >About</a>
                        <a id='navportfolio' href='/#expertise' onClick={() => setIsMenuOpen(false)}>Expertise</a>
                        <a id='navblog' href='/#experience' onClick={() => setIsMenuOpen(false)}>Experiences</a>
                        <a id='navmyWork' href='/#myWork' onClick={() => setIsMenuOpen(false)}>My Work</a>
                        <a id='navblogs'
                            onClick={() => setIsMenuOpen(false)}
                            rel='noopener noreferrer'
                            target='_blank'
                            href='https://medium.com/@isuruariyarathna2k00'>Blogs</a>
                    </div>
                    <div className='mobile-nav-contact'>
                        <a
                            onClick={() => setIsMenuOpen(false)}
                            href="/#getintouch"
                            className='btn'
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navigator;