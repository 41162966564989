import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { FaArrowRightLong } from "react-icons/fa6";

import PROJECTS from './Projects_data';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './Projects.scss'

AOS.init();
AOS.init({
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,

    offset: 120,
    delay: 0,
    duration: 800,
    easing: 'ease',
    once: false,
    mirror: false,
    anchorPlacement: 'top-bottom',
});

const Projects = () => {
    const projects = PROJECTS;
    const [visibleProjects, setVisibleProjects] = useState();
    const [isShowLoadMore, setIsShowLoadMore] = useState(true);

    useEffect(() => {
        setVisibleProjects(projects?.slice(0, 4));
    }, [projects])

    const loadMore = () => {
        const currentLength = visibleProjects?.length;
        const nextLength = currentLength + 4;
        const nextProjects = projects.slice(currentLength, nextLength);
        setVisibleProjects([...visibleProjects, ...nextProjects]);
        if (nextLength >= projects.length) {
            setIsShowLoadMore(false);
        }
    }

    const showLess = () => {
        setVisibleProjects(projects.slice(0, 4));
        setIsShowLoadMore(true);
    }

    const renderItems = () => {
        return visibleProjects?.map((project, index) => {
            return (
                <Grid item
                    xs={window.innerWidth < 800 ? 12 : project.xs}
                    key={index}
                    data-aos="zoom-in-up"
                >
                    <a
                        href={`/projects/${project.id}`}
                        rel='noreferrer'
                    >
                        <Paper className='item-body'>
                            <div className='top'>
                                <div className='image-container'>
                                    <img src={project.images[0]} alt={project.title} />
                                </div>
                            </div>
                            <div className='project-details-content'>
                                <div className='left'>
                                    <h3>{project.title}</h3>
                                    <p>{project.type}</p>
                                </div>
                                <div className='right'>
                                    <a href={`/projects/${project.id}`} rel='noopener noreferrer'>
                                        View Project <FaArrowRightLong />
                                    </a>
                                </div>
                            </div>
                        </Paper>
                    </a>
                </Grid>
            )
        })
    }

    return (
        <div className='projects-grid'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4}>
                    {renderItems()}
                </Grid>
            </Box>

            <div className='load-more'>
                {isShowLoadMore
                    ?
                    <button
                        onClick={loadMore}
                        class="btn"><i class="animation"></i>Load More<i class="animation"></i></button>
                    :
                    <button
                        onClick={showLess}
                        class="btn"><i class="animation"></i>Show Less<i class="animation"></i></button>
                }
            </div>


        </div>
    )
}

export default Projects